import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    tripStepEventFuelSearch: {},
  },
  mutations: {
    SET_FUEL_TYPE_ID(state, { tripStepEventId, fuelTypeId }) {
      if (!Object.hasOwn(state.tripStepEventFuelSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventFuelSearch, tripStepEventId, {
          fuelTypeId: null,
          fuelTypeName: '',
          volume: 0,
        })
      }
      state.tripStepEventFuelSearch[tripStepEventId].fuelTypeId = fuelTypeId
    },
    SET_VOLUME(state, { tripStepEventId, volume }) {
      if (!Object.hasOwn(state.tripStepEventFuelSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventFuelSearch, tripStepEventId, {
          fuelTypeId: null,
          fuelTypeName: '',
          volume: 0,
        })
      }
      state.tripStepEventFuelSearch[tripStepEventId].volume = volume
    },
    SET_FUEL_TYPE_NAME(state, { tripStepEventId, fuelTypeName }) {
      if (!Object.hasOwn(state.tripStepEventFuelSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventFuelSearch, tripStepEventId, {
          fuelTypeId: null,
          fuelTypeName: '',
          volume: 0,
        })
      }
      state.tripStepEventFuelSearch[tripStepEventId].fuelTypeName = fuelTypeName
    },
  },
  getters: {
    getFuelTypeIdByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventFuelSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventFuelSearch[tripStepEventId].fuelTypeId
    },
    getFuelTypeNameByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventFuelSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventFuelSearch[tripStepEventId].fuelTypeName
    },
    getVolumeByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventFuelSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventFuelSearch[tripStepEventId].volume
    },
  },
}
