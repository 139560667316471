import {
  postRegisterSelectOfferRequest,
} from '@/request/authApi/requests/apiRequests'

export default {
  namespaced: true,
  state: {
    paymentIntent: null
  },
  getters: {
    getPaymentIntent(state) {
      return state.paymentIntent
    },
  },
  mutations: {
    SET_PAYMENT_INTENT(state, paymentIntent) {
      state.paymentIntent = paymentIntent
    }
  },
  actions: {
    updatePaymentIntent({ commit }, paymentIntent) {
      commit('SET_PAYMENT_INTENT', paymentIntent)
    },
    fetchPaymentIntent({ commit }, { token, offerId }) {
      return new Promise((resolve, reject) => {
        postRegisterSelectOfferRequest(token, { offerId })
          .then(response => {
            commit('SET_PAYMENT_INTENT', response.data.paymentIntent)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
