export default {
  namespaced: true,
  state: {
    categoriesSelected: [],
  },

  mutations: {
    SET_CATEGORIES_SELECTED(state, catsId) {
      state.categoriesSelected = catsId
    },
  },
  actions: {
    selectCategories({ commit }, selectedCategoriesIds) {
      commit('SET_CATEGORIES_SELECTED', selectedCategoriesIds)
    },
  },
}
