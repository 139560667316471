<template>
  <div id="app" :class="['h-100', skinClasses]">
    <component :is="layout" v-show="i18nLoaded">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch, ref, getCurrentInstance } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { initI18n } from '@/libs/i18n'
import setFlatPickrLocale from '@/libs/flat-pickr'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store/store'

const LayoutVertical = () => import('@/layouts/layout-vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/layout-full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return 'layout-vertical'
    },
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        setFlatPickrLocale(value)
      },
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  setup(props, ctx) {
    const { $i18n, toaster } = getCurrentInstance()?.proxy.$root
    const { skin, skinClasses } = useAppConfig()
    const i18nLoaded = ref(false)

    initI18n().then(() => {
      const loadingEl = document.getElementById('loading-bg')
      loadingEl.classList.add('d-none')
      i18nLoaded.value = true
    })

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    if (localStorage.getItem('showConflictNotification') === 'true') {
      // Afficher la notification
      toaster($i18n.t('alert.conflict.notification'), 'danger', 'AlertTriangleIcon')

      // Supprimer l'indicateur de LocalStorage
      localStorage.removeItem('showConflictNotification');
    }

    return {
      skinClasses,
      i18nLoaded,
    }
  },
}
</script>
