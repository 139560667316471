import { getField, updateField } from 'vuex-map-fields'
import { fetchPriceManagementsRequest } from '@/request/globalApi/requests/priceManagementRequests'

export default {
  namespaced: true,
  state: {
    pricesManagementLoading: false,

    pricesManagement: [],
    pagination: {
      totalItems: 0, firstPage: 1, previousPage: 1, nextPage: 1, lastPage: 1,
    },
    queryParams: {
      nameOrder: 'asc',
      priceManagementStatus: null,
      page: 1,
      numberOfItemsPerPage: 0,
    },
    search: {
      name: null,
    },
    sorting: {
      sortBy: 'eee',
      sortDesc: false,
    },

    pricesManagementsShowed: [],

    pricesManagementNameLoading: false,
    pricesManagementName: [],
  },

  getters: {
    getField,

    priceManagementById: state => id => {
      const result = state.pricesManagementsShowed.find(item => item.id === id)
      return result
    },
  },

  mutations: {
    updateField,

    SET_PRICE_MANAGEMENT_LOADING: (state, loading) => {
      state.pricesManagementLoading = loading
    },

    SET_PRICE_MANAGEMENTS: (state, pricesManagement) => {
      state.pricesManagement = pricesManagement
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.sorting = sort
      state.queryParams.nameOrder = null
      state.queryParams[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },

    SET_PRICE_MANAGEMENTS_SHOWED: (state, priceManagement) => {
      state.pricesManagementsShowed.push(priceManagement)
    },

    CLEAR_PRICE_MANAGEMENTS_SHOWED: state => {
      state.pricesManagementsShowed = []
    },

    SET_PRICES_MANAGEMENT_NAME_LOADING: (state, loading) => {
      state.pricesManagementNameLoading = loading
    },

    SET_PRICES_MANAGEMENT_NAME: (state, pricesManagementName) => {
      state.pricesManagementName = pricesManagementName
    },
  },

  actions: {
    fetchPriceManagements({ commit, state, rootState }, refresh) {
      if (refresh || !state.pricesManagement.length) {
        state.queryParams.numberOfItemsPerPage === 0
        && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_PRICE_MANAGEMENT_LOADING', true)
        fetchPriceManagementsRequest({ ...state.queryParams, ...state.search })
          .then(response => {
            commit('SET_PRICE_MANAGEMENTS', response.data.priceManagements)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
              firstPage: response.data.firstPage,
              previousPage: response.data.previousPage,
              nextPage: response.data.nextPage,
              lastPage: response.data.lastPage,
            })
            commit('CLEAR_PRICE_MANAGEMENTS_SHOWED')
          })
          .finally(() => {
            commit('SET_PRICE_MANAGEMENT_LOADING', false)
          })
      }
    },

    setPriceManagementsShowed({ commit }, priceManagement) {
      commit('SET_PRICE_MANAGEMENTS_SHOWED', priceManagement)
    },

    clearPriceManagementsShowed({ commit }) {
      commit('CLEAR_PRICE_MANAGEMENTS_SHOWED')
    },

    setSort({ commit }, sort) {
      commit('SET_SORT', sort)
    },

    fetchPricesManagementName({ commit }) {
      fetchPriceManagementsRequest({ light: true, getAllItems: true }).then(response => {
        commit('SET_PRICES_MANAGEMENT_NAME', response.data.priceManagements)
      })
    },
  },
}
