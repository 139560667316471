import Vue from 'vue'
import Vuex from 'vuex'
import { fetchTripsExternalCalendarRequest } from '@/request/globalApi/requests/tripRequest'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    calendarExternalEvents: [],
    externalEvents: false,
  },
  getters: {
    calendarExternalEvents: state => state.calendarExternalEvents,
    externalEvents: state => state.externalEvents,
  },
  mutations: {
    SET_CALENDAR_EXTERNAL_EVENTS: (state, calendarExternalEvents) => {
      state.calendarExternalEvents = calendarExternalEvents
    },
    SET_EXTERNAL_EVENTS: (state, externalEvents) => {
      state.externalEvents = externalEvents
    },
  },
  actions: {
    updateCalendarExternalEvents({ state, commit }, params) {
      if (state.externalEvents === true) {
        return fetchTripsExternalCalendarRequest(params)
          .then(response => {
            commit('SET_CALENDAR_EXTERNAL_EVENTS', response.data.events)
          })
      }
      commit('SET_CALENDAR_EXTERNAL_EVENTS', [])
      return null
    },
    updateExternalEvents({ commit }, externalEvents) {
      commit('SET_EXTERNAL_EVENTS', externalEvents)
    },
  },
}
