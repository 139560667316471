import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const context = require.context('./modules', false, /.js$/)
const modules = {}

context.keys().forEach(file => {
  const moduleName = file.replace(/(\.\/|\.js$)/g, '')
  modules[moduleName] = context(file).default || context(file)
  modules[moduleName].namespaced = true
})

const actions = {
  resetAll({ dispatch }) {
    dispatch('quote/resetState', null, { root: true })
    dispatch('offer/resetState', null, { root: true })
    dispatch('trip/clearFilters', null, { root: true })
    dispatch('enum/resetState', null, { root: true })
  },
}

const store = new Vuex.Store({
  modules,
  plugins: debug ? [createLogger()] : [],
  strict: debug,
  actions,
})

// if (module.hot) {
//   module.hot.accept(context.id, () => {
//     const { modulesLoaded } = loadModules()

//     store.hotUpdate({
//       modules: { ...modulesLoaded },
//     })
//   })
// }

export default store
