import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {fetchTripStepGendecProvidersRequest} from "@/request/globalApi/requests/tripStepEventRequests";
import {fetchTripStepGendecRequest} from "@/request/globalApi/requests/tripStepRequests";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    gendecPerTripStep: {},
    gendecsLoading: false,
  },
  getters: {
    getField,
    gendecPerTripStep: state => state.gendecPerTripStep,
    gendecsLoading: state => state.gendecsLoading,
  },
  mutations: {
    updateField,
    SET_GENDEC_PER_TRIP_STEP(state, payload) {
      state.gendecPerTripStep[payload.stepId] = payload.gendecs
    },
    SET_GENDEC_LOADING(state, gendecsLoading) {
      state.gendecsLoading = gendecsLoading
    },
  },
  actions: {
    async fetchGendecPerTripStep({state, commit}, { stepId, force }) {
      console.log('fetchGendecPerTripStep', stepId, force)
      if (state.gendecPerTripStep[stepId] && !force) {
        return Promise.resolve()
      }

      commit('SET_GENDEC_LOADING', true)
      fetchTripStepGendecRequest(stepId, { getAllItems: true })
        .then(r => {
          const { gendecs } = r.data
          commit('SET_GENDEC_PER_TRIP_STEP', { stepId, gendecs })
        })
        .finally(() => {
          commit('SET_GENDEC_LOADING', false)
        })
    },
  }
}
