import ability from '@/libs/ability'

class RouteHelper {
  canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

  isPublicRoute = routeName => {
    const publicRoutes = [
      'login',
      'contact',
      'forgot-password',
      'forgot-password-validation',
      'reset-password',
      'reset-password-validation',
      'registration',
      'register',
      'register-validation',
      'register-validate',
      'registration-validation',
      'registration-user',
      'registration-user-valid',
      'registration-user-validation',
      'legal-notice',
      'privacy-policy',
      'terms-of-use',
    ]

    return publicRoutes.includes(routeName)
  }
}

export default new RouteHelper()
