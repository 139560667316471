import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
import axios from "axios";

const END_POINT = 'api/trip'

// MAIN
export const fetchTripsRequest = queryParams => globalApi.get(`${END_POINT}`, { params: queryParams })

export const fetchTripsCalendarRequest = queryParams => globalApi.get(`${END_POINT}/calendar`, { params: queryParams })

export const fetchTripsExternalCalendarRequest = queryParams => globalApi.get(`${END_POINT}/external-calendar`, { params: queryParams })

export const postTripRequest = ({ trip, message }) => globalApi.post(`${END_POINT}`, trip, { message })

export const deleteTripRequest = (tripId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${tripId}/delete`, { message }),
)

export const removeTripRequest = ({ tripId, message }) => globalApi.delete(`${END_POINT}/${tripId}/remove`, { message })

// INFOS
export const fetchTripInfosRequest = tripId => globalApi.get(`${END_POINT}/${tripId}/infos`)

export const fetchTripOrganizationInfosRequest = (tripId, queryParams) => globalApi.get(`${END_POINT}/${tripId}/organization-infos`, { params: queryParams })

export const postTripInfosRequest = ({ trip, message }) => globalApi.post(`${END_POINT}/${trip.id}/infos`, trip, { message })

export const editTripInfosRequest = ({ trip, message }) => globalApi.patch(`${END_POINT}/${trip.id}/infos`, trip, { message })

// AIRCRAFT
export const postTripAircraftsRequest = ({ trip, message }) => globalApi.post(`${END_POINT}/${trip.id}/aircraft`, trip, { message })

export const editTripAircraftRequest = ({ trip, message }) => globalApi.patch(`${END_POINT}/${trip.id}/aircraft`, trip, { message })

export const fetchAircraftFilters = () => globalApi.get(`${END_POINT}-filters`)

export const exportTrip = (tripId) => {
  const token = `Bearer ${localStorage.getItem('token')}` || ''
  const selectedOrganizationId = localStorage.getItem('selectedOrganization')
    && JSON.parse(localStorage.getItem('selectedOrganization')).id
  const lang = localStorage.getItem('lang')

  return axios.get(`${process.env.VUE_APP_API_BASE_URL}${END_POINT}/${tripId}/export`, {
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/pdf',
      Authorization: token,
      selectedOrganizationId,
      lang,
      measurement: localStorage.getItem('measurement') || 'metric',
    },
    responseType: 'arraybuffer',
  }).then(response => {
    const blob = new Blob(
      [response.data],
      { type: 'application/pdf' },
    )
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
      link.download = `trip-${tripId}.pdf`
    }

    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}
