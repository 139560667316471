import Vue from 'vue'
import { fetchEnumerationRequest } from '@/request/globalApi/requests/enumerationRequests'

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_ENUM: (state, enumeration) => {
      // assign enum to the store
      Vue.set(state, enumeration.enumClass, enumeration.values)
    },
    SET_ENUM_LOADING: (state, enumerationLoading) => {
      // assign enumLoading to the store
      Vue.set(state, enumerationLoading.enumClass, enumerationLoading.value)
    },
    RESET_STATE: state => {
      Object.keys(state).forEach(key => {
        Vue.delete(state, key);
      })
    }
  },
  actions: {
    fetchEnum(ctx, enumClass) {
      return fetchEnumerationRequest(`${enumClass}`)
    },
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    fetchEnum2({ commit, state }, enumClass) {
      // if enum not exist or is empty, request
      if (!state.hasOwnProperty(enumClass) || !state[enumClass].length) {
        commit('SET_ENUM_LOADING', { enumClass: `${enumClass}Loading`, value: true })
        return (
          // TODO await pass enum in URI in Pascal case
          fetchEnumerationRequest(`${enumClass[0].toUpperCase() + enumClass.substring(1)}`)
            // TODO await response type array like ['percent','currency'...]
            .then(response => {
              const values = []
              Object.keys(response.data).forEach(key => {
                values.push({ value: response.data[key], text: key })
              })
              response.data = values
              commit('SET_ENUM', { enumClass, values: response.data })
            })
            .finally(() => {
              commit('SET_ENUM_LOADING', { enumClass: `${enumClass}Loading`, value: false })
            })
        )
      }
    },
  },
}
