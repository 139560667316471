import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {fetchAirportRequest} from "@/request/globalApi/requests/airportRequests";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    airports: {},
    airportLoading: false,
  },
  getters: {
    getField,
    airports: state => state.airports,
    airportLoading: state => state.airportLoading,
  },
  mutations: {
    updateField,
    ADD_AIRPORT(state, airport) {
      state.airports[airport.id] = airport
    },
    SET_AIRPORT_LOADING(state, airportLoading) {
      state.airportLoading = airportLoading
    },
  },
  actions: {
    async fetchAirport({state, commit}, id) {
      if (state.airports[id]) {
        return Promise.resolve()
      }

      commit('SET_AIRPORT_LOADING', true)
      return fetchAirportRequest(id)
        .then(response => {
          commit('ADD_AIRPORT', response.data)
        })
        .finally(() => {
          commit('SET_AIRPORT_LOADING', false)
        })
    },
  }
}
