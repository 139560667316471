import { omit } from 'lodash'

import globalApi from '../globalApi'

const END_POINT = 'api/hangar'

// --------------------
//    API Requests
// --------------------

export const fetchHangarsVariantsRequest = queryParams => globalApi.get(`${END_POINT}/variant`, { params: queryParams })

export const fetchHangarVariantRequest = variantId => globalApi.get(`${END_POINT}/variant/${variantId}`)

export const fetchHangarSlicesNumber = () => globalApi.get(`${END_POINT}/hangar-slices-number`)

export const fetchHangarsVariantsDashboardRequest = (variantId, queryParams) => globalApi.get(`/api/hangar-variant/${variantId}/dashboard`, { params: queryParams })

export const fetchHangarsVariantsCalendarRequest = (variantId, queryParams) => globalApi.get(`/api/hangar-variant/${variantId}/calendar`, { params: queryParams })

export const fetchHangarsVariantsCalendarSlicesRequest = (variantId, queryParams) => globalApi.get(`/api/hangar-variant/${variantId}/calendar/slices`, { params: queryParams })

export const postHangarCustomEventRequest = payload => globalApi.post(`${END_POINT}/custom-event`, payload)

export const fetchHangarCustomEventRequest = customEventId => globalApi.get(`${END_POINT}/custom-event/${customEventId}`)

export const patchHangarCustomEventRequest = payload => globalApi.patch(`${END_POINT}/custom-event/${payload.id}`, omit(payload, 'id'))

export const deleteHangarCustomEventRequest = customEventd => globalApi.delete(`${END_POINT}/custom-event/${customEventd}`)
