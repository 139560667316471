const requestHandler = config => {
  const newConfig = config
  newConfig.headers.Authorization = localStorage.getItem('token')
    ? `Bearer ${localStorage.getItem('token')}`
    : ''
  newConfig.headers.selectedOrganizationId = localStorage.getItem('selectedOrganization')
    && JSON.parse(localStorage.getItem('selectedOrganization')).id
  newConfig.headers.measurement = localStorage.getItem('measurement') || 'metric'
  newConfig.headers.lang = localStorage.getItem('lang') || 'en'

  return newConfig
}

export default requestHandler
