import { getField, updateField } from 'vuex-map-fields'
import {fetchAttributesRequest} from "@/request/globalApi/requests/attributeRequests";

export default {
  namespaced: true,
  state: {
    attributesLoading: false,
    attributes: [],

    pagination: { totalItems: 0, firstPage: 1, previousPage: 1, nextPage: 1, lastPage: 1 },
    queryParams: {
      page: 1,
      numberOfItemsPerPage: 0,
      nameOrder: 'desc',
      filterableOrder: null,
      requiredOrder: null,
      typeOrder: null,
    },
    search: null,
    sorting: {
      sortBy: 'name',
      sortDesc: false,
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    SET_ATTRIBUTES_LOADING: (state, loading) => {
      state.attributesLoading = loading
    },

    SET_ATTRIBUTES: (state, attributes) => {
      state.attributes = attributes
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.sorting = sort
      state.queryParams.nameOrder = null
      state.queryParams.filterableOrder = null
      state.queryParams.requiredOrder = null
      state.queryParams.typeOrder = null
      state.queryParams[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },
  },

  actions: {
    fetchAttributes({ commit, state, rootState }, refresh) {
      if (refresh || !state.attributes.length) {
        state.queryParams.numberOfItemsPerPage === 0
        && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_ATTRIBUTES_LOADING', true)
        fetchAttributesRequest({ ...state.queryParams, search: state.search })
          .then(response => {
            commit('SET_ATTRIBUTES', response.data.attributes)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
              firstPage: response.data.firstPage,
              previousPage: response.data.previousPage,
              nextPage: response.data.nextPage,
              lastPage: response.data.lastPage,
            })
          })
          .finally(() => {
            commit('SET_ATTRIBUTES_LOADING', false)
          })
      }
    },

    setSort({ commit }, sort) {
      commit('SET_SORT', sort)
    },
  },
}
