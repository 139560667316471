/* eslint-disable import/no-cycle */
import store from '@/store/store'
import globalApi from '@/request/globalApi/globalApi'
import router from '@/router/router'
import { postTokenRefreshRequest } from '@/request/authApi/requests/apiRequests'

const _getCookie = cname => {
  const name = `${cname}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

const error401Handler = error => {
  const refreshToken = _getCookie('refreshToken')
  const redirectUrl = router.currentRoute.fullPath
  if (!refreshToken) {
    store.dispatch('auth/logout', {
      redirectUrl,
    })
    return Promise.reject(error)
  }

  const originalConfig = error.config
  if (error?.response?.status === 401 && !originalConfig._retry) {
    // TODO do not trigger other posttoken request
    return postTokenRefreshRequest({ refreshToken })
      .then(({ data }) => {
        // TODO set local storage with refresh and token
        store.dispatch('auth/updateToken', data.token)
        return globalApi(originalConfig)
      })
      .catch(() => {
        store.dispatch('auth/logout', {
          redirectUrl,
        })
      })
  }
  return null
}

export default error401Handler
