import { omit } from 'lodash'

import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
import axios from "axios";

const END_POINT = 'api/organization'

// ORGANIZATION
export const fetchOrganizationRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}`, { params })

export const fetchOrganizationsRequest = params => globalApi.get(END_POINT, { params })

export const deleteOrganizationRequest = organizationId => globalApi.delete(`${END_POINT}/${organizationId}/delete`)

// USERS
export const fetchOrganizationUsersRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/user`, { params })

export const fetchOrganizationUserRequest = (organizationId, organizationUserId) => globalApi.get(`${END_POINT}/${organizationId}/user/${organizationUserId}`)

export const postOrganizationUserRequest = (organizationId, organizationUser, message) => globalApi.post(`${END_POINT}/${organizationId}/user`, organizationUser, { message })

export const patchOrganizationUserRequest = (organizationId, organizationUser, message) => globalApi.patch(`${END_POINT}/${organizationId}/user/${organizationUser.id}`, omit(organizationUser, 'id'), { message })

export const patchOrganizationUserNewInvitationRequest = (organizationId, organizationUserId, data) => globalApi.patch(`${END_POINT}/${organizationId}/user/${organizationUserId}/new-invitation`, data)

export const deleteOrganizationUserRequest = (organizationId, organizationUserId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${organizationId}/user/${organizationUserId}`, { message }),
)
// RIGHTS
export const fetchOrganizationRightsRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/rights`)

// INFORMATIONS
export const fetchOrganizationInfosRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/infos`)

export const patchOrganizationInfosRequest = (organizationId, organizationInfos, message) => globalApi.patch(`${END_POINT}/${organizationId}/infos`, organizationInfos, { message })

// LEGALS
export const fetchOrganizationLegalRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/legal`)

export const patchOrganizationLegalRequest = (organizationId, organizationLegal, message) => globalApi.patch(`${END_POINT}/${organizationId}/legal`, organizationLegal, { message })

// OFFER
export const fetchOrganizationOfferRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/offer`)

export const fetchOrganizationPaymentsRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/offer-payments`, { params })

export const patchOrganizationOfferRequest = (organizationId, offersIds, message) => globalApi.patch(`${END_POINT}/${organizationId}/submit-offer`, offersIds, { message })

// PRESENTATION
export const fetchOrganizationPresentationRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/presentation`)

export const patchOrganizationPresentationRequest = (organizationId, organizationPresentation, message) => globalApi.patch(`${END_POINT}/${organizationId}/presentation`, organizationPresentation, { message })

// ACCOUNTING
export const fetchOrganizationAccountingRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/accounting`, { params })
export const fetchOrganizationInvoiceConfigurationRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/invoice-configuration`)
export const editOrganizationInvoiceConfigurationRequest = (organizationId, invoiceConfiguration, message) => globalApi.patch(`${END_POINT}/${organizationId}/invoice-configuration`, invoiceConfiguration, { message })

// PAYMENT
export const fetchOrganizationPaymentRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/payment`, { params })
export const fetchOrganizationBalanceRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/balance`)

// ADDRESSES
export const fetchOrganizationAdressesRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/address`, { params })

export const postOrganizationAddressRequest = (organizationId, organizationAddress, message) => globalApi.post(`${END_POINT}/${organizationId}/address`, organizationAddress, { message })

export const patchOrganizationAddressRequest = (organizationId, organizationAddress, message) => globalApi.patch(`${END_POINT}/${organizationId}/address/${organizationAddress.address.id}`, organizationAddress, { message })

export const deleteOrganizationAddressRequest = (organizationId, organizationAddressId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${organizationId}/address/${organizationAddressId}`, { message }),
)
// AIRPORTS
export const fetchOrganizationAirportsRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/airports`, { params })

export const patchOrganizationAirportsRequest = (organizationId, organizationAirports, message) => globalApi.patch(`${END_POINT}/${organizationId}/airports`, organizationAirports, { message })

// CONTACT
export const postOrganizationContactRequest = (organizationId, content, message) => globalApi.post(`${END_POINT}/${organizationId}/contact`, content, { message })

// KYC
export const fetchOrganizationKYCAccountLinkRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/kyc-account-link`, { params })

export const fetchOrganizationKYCRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/kyc`)

export const patchOrganizationKYCRequest = (organizationId, organizationKYC, message) => globalApi.patch(`${END_POINT}/${organizationId}/kyc`, organizationKYC, { message })

// EXTERNAL ACCOUNT
export const fetchOrganizationExternalAccountsRequest = organizationId => globalApi.get(`${END_POINT}/${organizationId}/external-account`)

export const fetchOrganizationExternalAccountRequest = (organizationId, externalAccountId) => globalApi.get(`${END_POINT}/${organizationId}/external-account/${externalAccountId}`)

export const postOrganizationExternalAccountRequest = (organizationId, externalAccount, message) => globalApi.post(`${END_POINT}/${organizationId}/external-account`, externalAccount, { message })

export const patchOrganizationExternalAccountRequest = (organizationId, externalAccount, message) => globalApi.patch(`${END_POINT}/${organizationId}/external-account/${externalAccount.id}`, externalAccount, { message })

export const deleteOrganizationExternalAccountRequest = (organizationId, externalAccountId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${organizationId}/external-account/${externalAccountId}`, { message }),
)

// TODO: create instance for download
export const downloadAccountingCSV = (organizationId) => {
    const token = `Bearer ${localStorage.getItem('token')}` || ''
    const selectedOrganizationId = localStorage.getItem('selectedOrganization')
        && JSON.parse(localStorage.getItem('selectedOrganization')).id
    const lang = localStorage.getItem('lang')

    axios.get(`${process.env.VUE_APP_API_BASE_URL}${END_POINT}/${organizationId}/accounting-export`, {
        headers: { Accept: 'text/csv', 'Content-Type': 'text/csv', Authorization: token, selectedOrganizationId, lang },
    }).then(response => {
        const blob = new Blob(
            [response.data],
            { type: 'text/csv' },
        )
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export.csv' // TODO dynamic name when instance created
        link.click()
    })
}