export default [
  {
    path: '/cancellation-policies',
    name: 'cancellationPolicies',
    component: () => import('@/views/cancellation-policy/CancellationPolicies.vue'),
    meta: {
      title: 'cancellation_policy.title',
      pln: 0,
      action: 'CANCELLATION_POLICY_VIEW',
      breadcrumb: [
        {
          text: 'navigation.cancellation_policies.title',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cancellation-policy',
    name: 'cancellationPolicy',
    component: () => import('@/views/cancellation-policy/CancellationPolicy.vue'),
    children: [
      {
        path: 'add',
        name: 'cancellationPolicyAdd',
        meta: {
          title: 'cancellation_policy.title',
          pln: 1,
          action: 'CANCELLATION_POLICY_ADD',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/cancellation-policies',
            },
            {
              text: 'action.add',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: ':id/edit',
        name: 'cancellationPolicyEdit',
        meta: {
          title: 'cancellation_policy.title',
          pln: 1,
          action: 'CANCELLATION_POLICY_EDIT',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/cancellation-policies',
            },
            {
              text: 'action.edit',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: ':id/show',
        name: 'cancellationPolicyShow',
        meta: {
          title: 'cancellation_policy.title',
          pln: 1,
          action: 'CANCELLATION_POLICY_VIEW',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/cancellation-policies',
            },
            {
              text: 'action.show',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: ':id/duplicate',
        name: 'cancellationPolicyDuplicate',
        meta: {
          title: 'cancellation_policy.title',
          pln: 1,
          action: 'CANCELLATION_POLICY_ADD',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/cancellation-policies',
            },
            {
              text: 'action.duplicate',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
]
