export default {
  namespaced: true,
  state: {
    searchText: '',
    searchFilter: 'eventCode',
  },
  getters: {
    searchText: state => state.searchText,
    searchFilter: state => state.searchFilter,
  },
  mutations: {
    setSearchText(state, value) {
      state.searchText = value
    },
    setSearchFilter(state, value) {
      state.searchFilter = value
    }
  },
  actions: {
    setSearchText({ commit }, value) {
      commit('setSearchText', value)
    },
    setSearchFilter({ commit }, value) {
      commit('setSearchFilter', value)
      commit('setSearchText', '')
    }
  },
}
