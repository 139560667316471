import globalApi from '@/request/globalApi/globalApi'
import axios from 'axios'

const END_POINT = 'api/marketplace/invoice'

export const fetchItemsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchItemFiltersRequest = () => new Promise(resolve => {
  const data = {
    data: {
      payment: [],
      type: [],
    },
  }

  resolve(data)
})

export const fetchItemStateRequest = () => new Promise(resolve => {
  const data = {
    data: [],
  }

  resolve(data)
})

// TODO: create instance for download
const token = `Bearer ${localStorage.getItem('token')}` || ''
const selectedOrganizationId = localStorage.getItem('selectedOrganization')
  && JSON.parse(localStorage.getItem('selectedOrganization')).id
const lang = localStorage.getItem('lang')
export const downloadMarketplaceInvoiceCSV = () => axios.get(`${process.env.VUE_APP_API_BASE_URL}api/export/marketplace/invoice`, {
  headers: { Accept: 'text/csv', 'Content-Type': 'text/csv', Authorization: token, selectedOrganizationId, lang },
}).then(response => {
  const blob = new Blob(
    [response.data],
    { type: 'text/csv' },
  )
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'export.csv' // TODO dynamic name when instance created
  link.click()
})
