import globalApi from '@/request/globalApi/globalApi'

const END_POINT = 'api/chat'
const END_POINT_MESSAGE = 'api/message'

export const fetchChatsRequest = queryParams => globalApi.get(`${END_POINT}`, { params: queryParams })

export const patchMessageChatRequest = (content, organizationId, fileId, message) => globalApi.patch(`${END_POINT}`, { content, organizationId, fileId }, message)

export const fetchChatRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const fetchUnreadMessagesRequest = () => globalApi.get(`${END_POINT_MESSAGE}/unread`)
