import {
  fetchHangarSlicesNumber,
  fetchHangarsVariantsDashboardRequest,
  fetchHangarsVariantsRequest,
} from '@/request/globalApi/requests/hangarRequests'
import { omit, pick } from 'lodash'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
    dashboardLoading: false,
    hangarsVariantsOptionsLoading: false,
    slicesNumber: null,
    slices: null,
    selectedSlices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    parkingSelectedSlices: [],
    dashboard: {},
    previousPage: null,
    nextPage: null,
    page: 1,
    totalItems: 0,
    hangarsVariantsOptions: [],
    selectedHangarVariant: null,
    loadedHangarVariantLoading: false,
    loadedHangarVariant: null,
  },
  mutations: {
    SET_DASHBOARD_LOADING(state, loading) {
      state.dashboardLoading = loading
    },
    SET_HANGARS_VARIANTS_OPTIONS_LOADING(state, loading) {
      state.hangarsVariantsOptionsLoading = loading
    },
    SET_SLICES_NUMBER(state, slicesNumber) {
      state.slicesNumber = slicesNumber
    },
    SET_SLICES(state, slices) {
      state.slices = slices
    },
    SET_SELECTED_SLICES(state, selectedSlices) {
      state.selectedSlices = selectedSlices
    },
    SET_SELECTED_PARKING_SLICES(state, parkingSelectedSlices) {
      state.parkingSelectedSlices = parkingSelectedSlices
    },
    SET_DASHBOARD(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_HANGARS_VARIANTS_OPTIONS(state, hangarsVariantsData) {
      Object.assign(state, {
        hangarsVariantsOptions: hangarsVariantsData.variants,
        ...pick(hangarsVariantsData, 'previousPage', 'nextPage', 'lastPage', 'totalItems'),
      })
    },
    SET_SELECTED_HANGAR_VARIANT(state, hangarVariant) {
      state.selectedHangarVariant = hangarVariant
    },
    SET_LOADED_HANGAR_VARIANT_LOADING(state, isLoading) {
      state.loadedHangarVariantLoading = isLoading
    },
    SET_LOADED_HANGAR_VARIANT(state, hangarVariant) {
      state.loadedHangarVariant = hangarVariant
    },
  },
  actions: {
    fetchHangarSlicesNumber({ commit, state }) {
      if (state.slicesNumber === null) {
        fetchHangarSlicesNumber().then(({ data }) => {
          commit('SET_SLICES_NUMBER', data.slicesNumber)
          commit('SET_SLICES', data.slices)
        })
      }
    },
    async fetchHangarsVariants({ commit, state }, refetch) {
      if (state.hangarsVariantsOptions.length && !refetch) return state.hangarsVariantsOptions
      commit('SET_HANGARS_VARIANTS_OPTIONS_LOADING', true)
      return fetchHangarsVariantsRequest({ page: state.page }).then(({ data }) => {
        commit('SET_HANGARS_VARIANTS_OPTIONS', data)
      }).finally(() => {
        commit('SET_HANGARS_VARIANTS_OPTIONS_LOADING', false)
      })
    },
    async fetchHangarsVariantsDashboard({ commit }, { variantId, queryParams = {} }) {
      commit('SET_DASHBOARD_LOADING', true)
      return fetchHangarsVariantsDashboardRequest(variantId, queryParams).then(({ data }) => {
        const formattedArrivals = []
        data.arrivals.forEach(arrival => {
          const formattedArrival = arrival
          if (formattedArrival.event && formattedArrival.event.customerContract === null) {
            formattedArrival.event.customerContract = {
              id: null,
              companyName: i18n.t('dashboard.custom'),
            }
          }

          formattedArrivals.push(formattedArrival)
        })

        const formattedDepartures = []
        data.departures.forEach(departure => {
          const formattedDeparture = departure
          if (formattedDeparture.event && formattedDeparture.event.customerContract === null) {
            formattedDeparture.event.customerContract = {
              id: null,
              companyName: i18n.t('dashboard.custom'),
            }
          }

          formattedDepartures.push(formattedDeparture)
        })

        commit('SET_DASHBOARD', {
          ...omit(data, 'arrivals', 'departures'),
          arrivals: formattedArrivals,
          departures: formattedDepartures,
        })
      }).finally(() => {
        commit('SET_DASHBOARD_LOADING', false)
      })
    }
  },
}
