import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/trip-step-event'

export const fetchCartRequest = (cartId, queryParams) => globalApi.get(`${END_POINT}/${cartId}/cart`, { params: queryParams })

export const patchCartRequest = (tripStepId, cart, message) => globalApi.patch(`${END_POINT}/${tripStepId}/cart-quantity`, cart, { message })

export const patchCartMandatoyPackageRequest = (tripStepId, cart, message) => globalApi.patch(`${END_POINT}/${tripStepId}/mandatory-package`, cart, { message })

export const patchCartDeliveryRequest = (tripStepId, cart, message) => globalApi.patch(`${END_POINT}/${tripStepId}/cart-deliver-date`, cart, { message })

export const deleteCartRequest = (tripStepId, cartLineId, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${tripStepId}/cart-line/${cartLineId}`, { message }))

export const patchCartValidateRequest = (tripStepId, message) => globalApi.patch(`${END_POINT}/${tripStepId}/cart-validate`, { message })

export const fetchTripStepProvidersRequest = tripStepId => globalApi.get(`${END_POINT}/${tripStepId}/provider`)
export const fetchTripStepGendecProvidersRequest = tripStepId => globalApi.get(`${END_POINT}/${tripStepId}/gendec-provider`)

export const fetchTripStepProviderQuoteLinesRequest = (tripStepId, providerId, queryParams) => globalApi.get(`${END_POINT}/${tripStepId}/provider/${providerId}/quote-line`, { params: queryParams })

export const fetchTripStepProviderQuotesRequest = (tripStepId, providerId, queryParams) => globalApi.get(`${END_POINT}/${tripStepId}/provider/${providerId}/quote`, { params: queryParams })

export const fetchTripStepProviderOrdersRequest = (tripStepId, providerId, queryParams) => globalApi.get(`${END_POINT}/${tripStepId}/provider/${providerId}/order`, { params: queryParams })

export const fetchTripStepAirportRequest = (tripStepEventId, airportId, queryParams) => globalApi.get(`${END_POINT}/${tripStepEventId}/airport/${airportId}`, { params: queryParams })

export const fetchTripStepProviderInvoicesRequest = (tripStepId, providerId) => globalApi.get(`${END_POINT}/${tripStepId}/provider/${providerId}/invoice`)

export const fetchTripStepProviderExternalInvoicesRequest = (tripStepId, providerId) => globalApi.get(`${END_POINT}/${tripStepId}/provider/${providerId}/external-invoice`)

export const patchCartCommentRequest = (tripStepId, cart, message) => globalApi.patch(`${END_POINT}/${tripStepId}/cart-comment`, cart, { message })

export const patchCartHangarRequest = (tripStepId, cart, message) => globalApi.patch(`${END_POINT}/${tripStepId}/hangar-cart-line`, cart, { message })

export const postTripStepEventCustomQuoteRequest = (tripStepEvent, message) => globalApi.post(`${END_POINT}/${tripStepEvent.id}/custom-quote`, tripStepEvent, message)

export const patchTripStepEventToAssignFboRequest = (tripStepEventId, fboId) => globalApi.patch(`${END_POINT}/${tripStepEventId}/assign-fbo/${fboId}`)

// HELICOPTER

export const fetchProductRegularFlightServiceDetailed = (tripStepEventId, requiredOrganizationId, destinationId, queryParams) => globalApi.get(`${END_POINT}/${tripStepEventId}/helicopters/${requiredOrganizationId}/regular-flight-helicopters/${destinationId}`, { params: queryParams })

export const fetchProductOnQuoteFlightServiceDetailed = (tripStepEventId, requiredOrganizationId, variantId) => globalApi.get(`${END_POINT}/${tripStepEventId}/helicopters/${requiredOrganizationId}/on-quote-flight-helicopters/${variantId}`)

export const postRequestRegularFlight = (tripStepEventId, requiredOrganizationId, helicopterVariantId, queryParams) => globalApi.post(`${END_POINT}/${tripStepEventId}/organization/${requiredOrganizationId}/regular-flight/${helicopterVariantId}/request`, queryParams)

export const postRequestOnQuoteFlight = (tripStepEventId, requiredOrganizationId, helicopterVariantId, queryParams) => globalApi.post(`${END_POINT}/${tripStepEventId}/organization/${requiredOrganizationId}/on-quote-flight/${helicopterVariantId}/request`, queryParams)

export const fetchTripStepEventRegularFlightHelicopterRequest = (tripStepId, organizationId) => globalApi.get(`${END_POINT}/${tripStepId}/organization/${organizationId}/regular-flight-helicopters`)

export const fetchTripStepEventOnQuoteHelicopterRequest = (tripStepId, organizationId, queryParams) => globalApi.get(`${END_POINT}/${tripStepId}/organization/${organizationId}/on-quote-helicopters`, { params: queryParams })

export const fetchTripStepEventOrganizationsOnQuoteRequest = (tripStepEventId, queryParams) => globalApi.get(`${END_POINT}/${tripStepEventId}/helicopters/on-quote-flight-organizations`, { params: queryParams })

export const fetchTripStepEventOrganizationsRegularFlightRequest = (tripStepEventId, queryParams) => globalApi.get(`${END_POINT}/${tripStepEventId}/helicopters/regular-flight-organizations`, { params: queryParams })

export const fetchTripStepEventOrganizationBasesRequest = (tripStepEventId, requiredOrganizationId) => globalApi.get(`${END_POINT}/${tripStepEventId}/organization/${requiredOrganizationId}/bases`)

export const fetchTripStepTransferDepositZonesRequest = tripStepEventId => globalApi.get(`api/trip-step-transfer/${tripStepEventId}/deposit-zones`)

export const patchTripStepTransferDepositZonesRequest = (tripStepEventId, params) => globalApi.patch(`api/trip-step-transfer/${tripStepEventId}/deposit-zones`, params)
